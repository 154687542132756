export function dateFormatter(date) {
    let dateLength = 10 
    let formattedDate = date.toISOString()
    formattedDate = formattedDate.substring(0,dateLength)
    return formattedDate
}

export function timeFormatter(time){
    let timeLength = 5
    let newTime  = time.toTimeString().substring(0,timeLength)
    return newTime
}