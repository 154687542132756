<script>
import { ref, toRef } from 'vue'
export default {

    setup(props, { emit, attrs }){

        const searchQuery = toRef(attrs, 'modelValue')

        const collapse = ref(true)
        const toggleCollapse = (type) => {
            
            emit('toggle-search', !type)
            if(type) emit('update:modelValue', '')
            collapse.value = type
        
        }


        const inputReceived = (event) => { 
            emit('update:modelValue', event.target.value)
        }

        return {
            collapse,
            toggleCollapse,
            inputReceived,
            searchQuery
        }

    }
}

</script>

<template>
    <div class="search">
        <button class="search__button" v-if="collapse" @click="toggleCollapse(false)"><img src="@/assets/images/search.png" alt=""></button>
        <div v-else>
            <input class="search__input" type="text" :value="searchQuery" @input="inputReceived" placeholder="search">
            <button class="search__close" @click="toggleCollapse(true)">x</button>
        </div>
    
    </div>
</template>

<style>

    .search{
        position: relative;
        display: flex;


        padding:.4em 0;
        min-height: 42px;
    }
    .search__input{
        box-sizing: border-box;
        border:1px solid #ccc;
        height: 100%;
        outline:none;
        padding:0 1em;
        border-radius: 4px;
    }

    .search__button{
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        background:transparent;
        border: none;
        padding:0 .75em;
        box-shadow: var(--shadow-button);
    }

    .search__close{
        font-weight: bold;
        border:none;
        position:absolute;
        padding:0 .8em;
        right:0;
        top:.4em;
        bottom: .4em;
         border:1px solid #ccc;
    }
    
    .search__input:focus{
        outline: none;
        border:1px solid #ccc;
    }

    .search__button img{
        width: 1em;
    }
    </style>