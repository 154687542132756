<template>
  <div class="list-header-container">
    
    <div class="list-header">
      <button class="list-header__back" @click="goBack">
        <img class="list-header__back-image" src="@/assets/images/left-arrow.svg" alt="back">
      </button>
      <h1 class="list-header__heading" v-show="!searchOpen">
        <span class="list-header--bold">{{ pageTitle }} {{ nameModifier }}</span>
        <!-- <span v-show="dataLabel">{{ " | " + dataLabel }}</span>
        <span v-show="branch">{{ ` (${branch}) ` }}</span> -->
      </h1>
      
      <list-search v-if="searchBoxShow" v-model="searchQuery" @toggle-search="newValue => searchOpen = newValue"/>


      <div class="list-header__buttons" v-if="buttons" v-show="!loading">
        <template v-for="button in buttons" :key="button" >
          <template v-if="userRoleFilter(button)">
            <button class="list-header__icon-button" v-if="button.type==='icon'" @click="buttonClick(button)">
              <img  class="list-header__icon" :src="require(`@/assets/images/${button.name}`)" alt="" srcset="">
            </button>

            <date-button v-else-if="button.type==='date'" />
            
            

            <button v-else class="list-header__button" :class="{'list-header__button--action': Boolean(button.action)}" 
            @click="buttonClick(button)">
              <div class="list-header__button-loading" v-if="selectedButton"><filling-circle color="#ffffff" :size="16" /></div>
              <span v-else>+ {{ button.name }}</span>
            </button>
          </template>
        </template>
      </div>
      
    </div>
  </div>
  
</template>

<script>
import ListSearch from '@/components/ListHeaderSearch.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import DateButton from '@/components/Date/DateTimeButton.vue'
export default {
  name: 'ListHeader',
  components:{FillingCircle,DateButton,ListSearch},


  data(){
    return {
      searchQuery:'',
      searchOpen: false,
    }
  },

  watch:{
    searchQuery(newQuery){
      this.$store.commit({type:'setHeaderSearchQuery',headerSearchQuery:newQuery})
    }
  },

  props:{
    nameModifier:String,
    buttonFilter:String,
    branch:String,
    dataLabel:String,
    loading:Boolean,
  },


  computed:{
    isVendor(){
      return this.$store.state.user.user_type === 'Vendor'
    },
    selectedButton(){
      return this.$store.state[this.state].loadingButton
    },
    id(){
      return this.$route.params.id
    },
    state(){
      let state = this.$route.meta['OPTIONS'].state
      return state ? state.name : ''
    },
    pageTitle(){
      let name = ""
      if(this.nameModifier){
        name = this.$route.meta['TITLE_BAR_DATA'].name.singular
      }else{
        name = this.$route.meta['TITLE_BAR_DATA'].name.plural
      }
      return name || 'Not set'
    },

    userRoles(){
      return this.$store.state.user.user_role
    },

    buttons(){
      let buttons = this.$route.meta['TITLE_BAR_DATA'].buttons.filter((button)=>button.name!==this.buttonFilter)
      return buttons
    },

    searchBoxShow(){
      return this.$route.meta['TITLE_BAR_DATA'].search
    }
  },
  
  methods:{
    userRoleFilter(button){
      
      let filter = true

      if(this.userRoles && button.userRole){

        if(Array.isArray(button.userRole)){
          
          filter = false
          for(let role of button.userRole){
            filter = filter || this.userRoles[role]
          }

        }else{
          filter = this.userRoles[button.userRole]
        }

      }
      

      return filter
    },
    showButton(){
      if (!this.buttonConfig){
        return true
      }
      
    },
    buttonClick(button){
      
      if(!this.selectedButton){

        if (button.pathName){
          
          this.$router.push({name:button.pathName})
        } 
        else if(button.action){
  
          this.$store.commit({type:`${this.state}/setLoadingButton`,loadingButton:button.name})
          
          this.$store.dispatch({type:button.action,id:this.id}).then(()=>{
            this.$store.commit({type:`${this.state}/setLoadingButton`,loadingButton:null})
          }).catch(error=>{
            this.$notify({
                title: "Failed to Change Order State",
                text: error.data,
                type:"error"
            })
            this.$store.commit({type:`${this.state}/setLoadingButton`,loadingButton:null})
            console.log(error)
          })
        } 
      }
    },
    goBack(){
      this.$router.go(-1);
    }
  },

}
</script>