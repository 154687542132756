<template>
    <button class="date__button list-header__icon-button" @click="datePopoverShow(true)">
        <img class="date__image" src="@/assets/images/calendar.png" alt="">
    </button>
    
    <div @click.self="datePopoverShow(false)" class="date" v-show="datePopover">
        <div class="date__container">
            <span @click="datePopoverShow(false)" class="date__close"><img src="@/assets/images/close.svg" alt="X" width="14"></span>
            <v-date-picker :attributes='attributes' is-range is-expanded v-model="range" mode="dateTime" :model-config="modelConfig" is24hrn:masks="masks" />
        </div>   
    </div>
   
            
</template>

<script>
import { dateFormatter,timeFormatter } from '@/helpers/DateTimeFormatter.js'
export default{
    data(){
        let startDate = new Date()
        let endDate = new Date()
        startDate.setHours(0, 0, 0)

        endDate.setHours(23, 59, 59)

        
        
        return{
            datePopover:false,
            attributes: [
            {
            
                popover: { 
                    placement:'top', 
                }
                
            }],
            masks: {
                inputDateTime24hr: 'YYYY-MM-DD HH:mm',
            },
            range: {
                start: startDate,
                end: endDate,
            },

            modelConfig: {
                start: {
                    timeAdjust: '00:00:00',
                },
                end: {
                    timeAdjust: '23:59:59',
                },
            },
        }
    },

    watch: {
        // whenever question changes, this function will run
        
        range: function (newRange) {
        
            this.$store.commit({
                type:'analysis/setTransactionsDateTimeRange',
                transactionsDateTimeRange:{
                    fromDate: dateFormatter(newRange.start) , 
                    fromTime: timeFormatter(newRange.start), 
                    toDate: dateFormatter(newRange.end), 
                    toTime: timeFormatter(newRange.end),
                }
            })
        }
    },

    methods:{
        datePopoverShow(showToggle){
            this.datePopover = showToggle
        }
    },

    mounted(){
        this.$store.commit({
            type:'analysis/setTransactionsDateTimeRange',
            transactionsDateTimeRange:{
                fromDate: dateFormatter(this.range.start) , 
                fromTime: timeFormatter(this.range.start), 
                toDate: dateFormatter(this.range.end), 
                toTime: timeFormatter(this.range.end),
            }
        })
        
    }

}
</script>

<style>
    .date{
        position: fixed;
        top:0;
        right:0;
        left:0;
        bottom: 0;
        z-index: 100;

        display:flex;
        align-items: center;
        justify-content: center;
        padding:1em;
        background: rgba(0,0,0,.5);
    }

    .date__button{
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-dark);
    }


    .date__container{
        flex:1;
        max-width: 600px;
        background: #fff;
        border-radius: var(--border-radius);
    }

    .date__image{
        width:1em;
    }

    .date__close{
        cursor: pointer;
        
        display: block;
        width: max-content;
        

        color:var(--bg-dark);
        font-weight: bold;
        margin:.5em;
        margin-left: auto;
        padding:.5em;
    }

    .vc-container{
        border-radius:none;
    }


</style>